<template>
  <b-modal
    v-model="showModal"
    v-if="certificate.certificate"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <div class="modal-header">
      <h5 class="modal-title">{{
        `Log History ${certificate.certificate.name} (${certificate.vehicle.name})`
      }}</h5>
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <DetailCertificate :certificate="certificate" />

    <tab-nav :tabs="true">
      <tab-nav-items
        :active="true"
        id="logs-tab-button"
        ariaControls="logs-tab"
        role="tab"
        :ariaSelected="true"
        title="Log"
      />
      <tab-nav-items
        v-if="certificate.certificate.certificateType !== 'SHORT_TERM'"
        :active="false"
        id="endorsement-tab-button"
        ariaControls="endorsement-tab"
        role="tab"
        :ariaSelected="false"
        title="Endorsements"
      />
    </tab-nav>
    <div id="tab-modal-log-history" class="tab-content">
      <tab-content-item :active="true" id="logs-tab">
        <b-col md="12">
          <div v-if="loading_certificate_logs" class="text-center my-5">
            <b-spinner type="grow" label="Spinning"></b-spinner>
          </div>
          <template v-else>
            <div class="table-responsive" v-if="certificate_logs.length > 0">
              <table class="table table-striped table-dark">
                <thead>
                <tr>
                  <th>No</th>
                  <th>Type</th>
                  <th>Process Date</th>
                  <th>Expired</th>
                  <th>Notes</th>
                  <th>File</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(log, i_log) in certificate_logs"
                  :key="`log-${i_log}`"
                >
                  <td>{{ (i_log += 1) }}</td>
                  <td>{{ log.type }}</td>
                  <td
                  ><strong>{{
                      log.processDate ? log.processDate : '-'
                    }}</strong></td
                  >
                  <td
                  ><strong>{{
                      log.dateExpired ? log.dateExpired : '-'
                    }}</strong></td
                  >
                  <td
                  ><strong>{{ log.notes ? log.notes : '-' }}</strong></td
                  >
                  <td>
                    <template v-if="log.file">
                      <a :href="log.file.url" target="_blank"
                         v-b-tooltip.hover
                         :title="certificate.file.fileName">
                        <i class="fa fa-download"></i>
                      </a>
                    </template>
                    <span v-else>-</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <p v-else class="text-center my-4">No logs data found.</p>
          </template>
        </b-col>
      </tab-content-item>
      <tab-content-item id="endorsement-tab">
        <b-col md="12">
          <div class="table-responsive" v-if="endorsements.length > 0">
            <table class="table table-striped table-dark">
              <thead>
              <tr>
                <th>No</th>
                <th>Alert By System</th>
                <th>Process Date</th>
                <th>Notes</th>
                <th>File</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(endrosement, endorsementIndex) in endorsements"
                :key="`endorsement-${endorsementIndex}`"
              >
                <td>{{ (endorsementIndex += 1) }}</td>
                <td>{{ endrosement.endEndorseDate || '-' }}</td>
                <td>{{ endrosement.processDate || '-' }}</td>
                <td>{{ endrosement.notes || '-' }}</td>
                <td>
                  <template v-if="endrosement.file">
                    <a :href="endrosement.file.url" target="_blank"
                       v-b-tooltip.hover
                       :title="endrosement.file.fileName">
                      <i class="fa fa-download"></i>
                    </a>
                  </template>
                  <span v-else>-</span>
                </td>
              </tr>
              </tbody>
            </table>
            <b-button v-if="endorsements.length !== totalAllEndorsements" @click="onLoadMoreEndorsement()" variant="primary" class="mt-3" block>Load More</b-button>
          </div>
          <p v-else class="text-center my-4">No Endorsements data found.</p>
        </b-col>
      </tab-content-item>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { certificatesActions } from '@src/Utils/helper'

export default {
  name: 'ModalLogHistory',
  components: {
    DetailCertificate: () =>
      import('@components/certificate/DetailCertificate'),
  },
  computed: {
    ...mapState('certificates', {
      modal_log: (state) => state.modal_log,
    }),
    showModal() {
      return this.modal_log.show
    },
    certificate() {
      return this.modal_log.certificate
    },
    totalAllEndorsements () {
      return this.certificate.endorsement.length
    },
    endorsements () {
      return this.certificate.endorsement.slice(this.startEndorsementLength, this.endEndorsementLength)
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.fetchCertificateLogs(this.certificate)
      }
    }
  },
  data() {
    return {
      startEndorsementLength: 0,
      endEndorsementLength: 5,
      perPageEndorsement: 5,
      loading_certificate_logs: false,
      certificate_logs: [],
    }
  },
  methods: {
    ...certificatesActions,
    async fetchCertificateLogs(certificate) {
      this.loading_certificate_logs = true
      this.certificate_logs = []
      let params = { page: 1 }
      let res = await this.getCertificateVehicleLogs({
        params,
        id: certificate.id,
      })

      this.certificate_logs = res.data
      this.loading_certificate_logs = false
    },
    onLoadMoreEndorsement() {
      if(this.endorsements.length !== this.totalAllEndorsements) {
        this.endEndorsementLength += this.perPageEndorsement
      }
    }
  },
}
</script>
