var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.certificate.certificate ? _c('b-modal', {
    attrs: {
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-header": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s("Log History ".concat(_vm.certificate.certificate.name, " (").concat(_vm.certificate.vehicle.name, ")")))]), _c('button', {
    staticClass: "close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('DetailCertificate', {
    attrs: {
      "certificate": _vm.certificate
    }
  }), _c('tab-nav', {
    attrs: {
      "tabs": true
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "id": "logs-tab-button",
      "ariaControls": "logs-tab",
      "role": "tab",
      "ariaSelected": true,
      "title": "Log"
    }
  }), _vm.certificate.certificate.certificateType !== 'SHORT_TERM' ? _c('tab-nav-items', {
    attrs: {
      "active": false,
      "id": "endorsement-tab-button",
      "ariaControls": "endorsement-tab",
      "role": "tab",
      "ariaSelected": false,
      "title": "Endorsements"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "tab-modal-log-history"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "logs-tab"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.loading_certificate_logs ? _c('div', {
    staticClass: "text-center my-5"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1) : [_vm.certificate_logs.length > 0 ? _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped table-dark"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Process Date")]), _c('th', [_vm._v("Expired")]), _c('th', [_vm._v("Notes")]), _c('th', [_vm._v("File")])])]), _c('tbody', _vm._l(_vm.certificate_logs, function (log, i_log) {
    return _c('tr', {
      key: "log-".concat(i_log)
    }, [_c('td', [_vm._v(_vm._s(i_log += 1))]), _c('td', [_vm._v(_vm._s(log.type))]), _c('td', [_c('strong', [_vm._v(_vm._s(log.processDate ? log.processDate : '-'))])]), _c('td', [_c('strong', [_vm._v(_vm._s(log.dateExpired ? log.dateExpired : '-'))])]), _c('td', [_c('strong', [_vm._v(_vm._s(log.notes ? log.notes : '-'))])]), _c('td', [log.file ? [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": log.file.url,
        "target": "_blank",
        "title": _vm.certificate.file.fileName
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })])] : _c('span', [_vm._v("-")])], 2)]);
  }), 0)])]) : _c('p', {
    staticClass: "text-center my-4"
  }, [_vm._v("No logs data found.")])]], 2)], 1), _c('tab-content-item', {
    attrs: {
      "id": "endorsement-tab"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.endorsements.length > 0 ? _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped table-dark"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Alert By System")]), _c('th', [_vm._v("Process Date")]), _c('th', [_vm._v("Notes")]), _c('th', [_vm._v("File")])])]), _c('tbody', _vm._l(_vm.endorsements, function (endrosement, endorsementIndex) {
    return _c('tr', {
      key: "endorsement-".concat(endorsementIndex)
    }, [_c('td', [_vm._v(_vm._s(endorsementIndex += 1))]), _c('td', [_vm._v(_vm._s(endrosement.endEndorseDate || '-'))]), _c('td', [_vm._v(_vm._s(endrosement.processDate || '-'))]), _c('td', [_vm._v(_vm._s(endrosement.notes || '-'))]), _c('td', [endrosement.file ? [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": endrosement.file.url,
        "target": "_blank",
        "title": endrosement.file.fileName
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })])] : _c('span', [_vm._v("-")])], 2)]);
  }), 0)]), _vm.endorsements.length !== _vm.totalAllEndorsements ? _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onLoadMoreEndorsement();
      }
    }
  }, [_vm._v("Load More")]) : _vm._e()], 1) : _c('p', {
    staticClass: "text-center my-4"
  }, [_vm._v("No Endorsements data found.")])])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }